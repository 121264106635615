import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { connectRouteSetting } from '../../store';

import './notFound.scss';

const NotFound = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  return(
    <div className="not_found_container">
      <div className="content1">
        <div className="logo"></div>
        <p className="title">{t('not_found_title')}</p>
        <p className="description">{t('not_found_description')}</p>
        <div className="buttons">
          <div className="button_white" onClick={() => {
            navigate(-1);
          }}>
            {t('not_found_button1')}
            <div className="button_arrow"></div>
          </div>
          <Link 
            className="button_pupple"
            onClick={() => {
              dispatch(connectRouteSetting('Main'));
            }}
            to={'/'}
          >
            {t('not_found_button2')}
            <div className="button_arrow"></div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;