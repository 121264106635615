import { Dispatch, SetStateAction } from "react";

import { NormalButton } from '../../index';

type AboutBoxProps = {
  title:string;
  subtitle?: string;
  description: string;
  button?: string;
  arrow?: boolean;
  disabled?: boolean;
  onClick?: Dispatch<SetStateAction<string>>;
}

const AboutBox = ({ title, subtitle, description, button, arrow, disabled, onClick }: AboutBoxProps) => {
  if (button === null || button === undefined) {
    return (
      <div
        className="content_box"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="image"></div>
        <div className="content_box_inner">
          {subtitle ? <p className="sub-title">{subtitle}</p> : null}
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="content_box"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="image"></div>
        <div className="content_box_inner">
          {subtitle ? <p className="sub-title">{subtitle}</p> : null}
          <p className="title">{title}</p>
          <p className="description">{description}</p>
          {arrow === true ? (<NormalButton button={button} arrow={arrow} disabled={disabled} onClick={onClick}/>) : (<NormalButton button={button} disabled={disabled} onClick={onClick}/>)}
        </div>
      </div>
    );
  }
}

export default AboutBox;