import { useTranslation } from 'react-i18next';

import { AboutBox, TechBox, MacroBox, RoadMapBox } from '../../components';

import "./exais.scss";

const Exais = () => {

  const { t } = useTranslation();

  return (
    <div className="exais_container">

      {/* content1 */}
      <div className="content1"></div>

      {/* position:relative; top: -50px;을 위해 container_inner로 묶어주게 되었습니다. */}
      <div className="container_inner">
        {/* content2 */}
        <div className="content2">
          <AboutBox title={t('exais_content2_box1_title')} description={t('exais_content2_box1_description')} />
          <AboutBox title={t('exais_content2_box2_title')} description={t('exais_content2_box2_description')} />
        </div>

        {/* content3 */}
        <div className="content3">
          <p className="title">{t('exais_content3_title')}</p>
          <p className="description">{t('exais_content3_description')}</p>
          <div className="content3_box">
            <TechBox title={t('exais_content3_box1_title')} description={t('exais_content3_box1_description')} subtitle={t('exais_content3_box1_subtitle')} />
            <TechBox title={t('exais_content3_box2_title')} description={t('exais_content3_box2_description')} />
            <TechBox title={t('exais_content3_box3_title')} description={t('exais_content3_box3_description')} />
            <TechBox title={t('exais_content3_box4_title')} description={t('exais_content3_box4_description')} />
            <TechBox title={t('exais_content3_box5_title')} description={t('exais_content3_box5_description')} />
            <TechBox title={t('exais_content3_box6_title')} description={t('exais_content3_box6_description')} />
          </div>
        </div>

        {/* content4 */}
        <div className="content4">
          <MacroBox
            title={t('exais_content4_box1_title')}
            title1={t('exais_content4_box1_market_cap')}
            value1={"3,088,237"}
            title2={t('exais_content4_box1_collateral')}
            value2={"$1"}
            title3={t('exais_content4_box1_accounts')}
            value3={"4,000"}
            title4={t('exais_content4_box1_circulating_supply')}
            value4={"1,000,000"}
            title5={t('exais_content4_box1_exa_price')}
            value5={"N/A"}
          />
          <MacroBox
            title={t('exais_content4_box2_title')}
            title1={t('exais_content4_box2_lasted_block')}
            value1={"3,088,237"}
            title2={t('exais_content4_box2_gas_price')}
            value2={"$1"}
            title3={t('exais_content4_box2_tps')}
            value3={"4,000"}
            title4={t('exais_content4_box2_block_interval')}
            value4={"1,000,000"}
            title5={t('exais_content4_box2_exa_price')}
            value5={"N/A"}
          />
        </div>

        {/* content5 */}
        <div className="content5">
          <p
            className="title"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('exais_content5_title')}
          </p>
          <p
            className="description"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {t('exais_content5_description')}
          </p>
          <div className="content5_inner">
            <RoadMapBox date={'’22.09'} description={t(('exais_content5_box1_description'))} />
            <RoadMapBox date={'’22.10'} description={t(('exais_content5_box2_description'))} />
            <RoadMapBox date={'’22.11'} description={t(('exais_content5_box3_description'))} />
            <RoadMapBox date={'’22.12'} description={t(('exais_content5_box4_description'))} />
            <RoadMapBox date={'’23.02'} description={t(('exais_content5_box5_description'))} />
            <RoadMapBox date={'’23.05'} description={t(('exais_content5_box6_description'))} />
          </div>
        </div>

        {/* content6 */}
        <div className="content6">
          <AboutBox title={t('exais_content6_box1_title')} description={t('exais_content6_box1_description')} />
          <AboutBox title={t('exais_content6_box2_title')} description={t('exais_content6_box2_description')} button={t('exais_content6_box2_button')} arrow={true} />
        </div>
      </div>


    </div>
  );
}

export default Exais;