type MacroBoxProps = {
  title:string;
  title1:string;
  value1:string;
  title2: string;
  value2: string;
  title3: string;
  value3: string;
  title4: string;
  value4: string;
  title5: string;
  value5: string;
}

type MacroBoxInnerProps = {
  value:string;
  title:string;
}

const MacroBoxInner = ({value, title}:MacroBoxInnerProps) => {
    return (
        <div
            className="content4_inner_box"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
            <p className="value">{value}</p>
            <p className="title">{title}</p>
        </div>
    );
}

const MacroBox = ({ title, title1, value1, title2, value2, title3, value3, title4, value4, title5, value5 }: MacroBoxProps ) => {
    return (
        <div
            className="content4_inner"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
            <p className="title">{title}</p>
            <div className="content4_inner_inner">
                <MacroBoxInner title={title1} value={value1} />
                <MacroBoxInner title={title2} value={value2} />
                <MacroBoxInner title={title3} value={value3} />
                <MacroBoxInner title={title4} value={value4} />
                <MacroBoxInner title={title5} value={value5} />
            </div>
        </div>
    );
}

export default MacroBox;