type Main6BoxProps = {
  title: string;
  description: string;
}

const Main6Box = ({title, description}: Main6BoxProps) => {
  return(
    <div className="box">
      <div className="image"></div>
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
}

export default Main6Box;