import classNames from "classnames";

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, connectModalSetting } from '../../store';

import { NormalButton, RoadMapBox, Main6Box, TeamBox } from '../../components';

import "./main2.scss";

const Main2: React.FC = () => {
  
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);

  return (
    <div 
      // className="main2_container"
      className={classNames(`main2_container`, {ko : i18n.language === "ko-KR"})}
    >
      {/* main box 1 */}
      <div className="main_box1">
        <div className="main_box1_inner">
          <h1 className="title">{t('main2_title')}</h1>
          <p className="description">{t('main2_description')}</p>
          <div 
            className="arrow_button"
            onClick={() => {
              // dispatch(connectModalSetting(!modalOpen));
              window.open(`https://drive.google.com/file/d/1M2ZT8qu9pw3Q-vUL4H83Qftp_bcZC-P2/view?usp=sharing`, `_blank`);
            }}
          >
            <p>{t('white_paper')}</p>
            <div className="button_arrow"></div>
          </div>
        </div>
      </div>
      {/* main box 2 */}
      <div className="main_box2">
        <div className="main_box2_inner">
          <div className="content_box">
            <h2 className="title">{t('main2_content2_inner1_title')}</h2>
            <p className="description">{t('main2_content2_inner1_description')}</p>
          </div>
          <div className="image"></div>
        </div>
        <div className="main_box2_inner">
          <div className="content_box">
            <h2 className="title">{t('main2_content2_inner2_title')}</h2>
            <p className="description">{t('main2_content2_inner2_description')}</p>
          </div>
          <div className="image"></div>
        </div>
        <div className="main_box2_inner">
          <div className="content_box">
            <h2 className="title">{t('main2_content2_inner3_title')}</h2>
            <p className="description">{t('main2_content2_inner3_description')}</p>
          </div>
          <div className="image"></div>
        </div>
      </div>

      {/* main box 3 */}
      <div className="main_box3">
        <div className="main_box3_inner">
          <div className="content_box">
            <p className="title">{t('main2_content3_title')}</p>
            <p className="description">{t('main2_content3_description')}</p>
          </div>
          <div className="content_box">
            <div className="content">
              <p className="title">{t('main2_content3_inner1_title')}</p>
              <p className="description">{t('main2_content3_inner1_description')}</p>
            </div>
            <div className="content">
              <p className="title">{t('main2_content3_inner2_title')}</p>
              <p className="description">{t('main2_content3_inner2_description')}</p>
            </div>
          </div>
        </div>
      </div>

      {/* main box 4 */}
      <div className="main_box4">
        <div className="main_box4_inner">
          <p className="title">{t('main2_content4_title')}</p>
          <p className="description">{t('main2_content4_description')}</p>
        </div>
      </div>

      {/* main box 5 */}
      <div className="main_box5">
        <h2 className="title">{t('main2_content5_title')}</h2>
        <p className="description">{t('main2_content5_description')}</p>
        <div className="content5_inner">
          <RoadMapBox date={'’22.11'} description={t(('main2_content5_inner1'))} />
          <RoadMapBox date={'’22.12'} description={t(('main2_content5_inner2'))} />
          <RoadMapBox date={'’23.1Q'} description={t(('main2_content5_inner3'))} />
          <RoadMapBox date={'’23.2Q'} description={t(('main2_content5_inner4'))} />
          <RoadMapBox date={'’23.3Q'} description={t(('main2_content5_inner5'))} />
          <RoadMapBox date={'’23.4Q'} description={t(('main2_content5_inner6'))} />
          <RoadMapBox date={'’24.1Q'} description={t(('main2_content5_inner7'))} />
        </div>
      </div>

      {/* main box 6 */}
      <div className="main_box6">
        <div className="main_box6_inner">
          <h2 className="title">{t('main2_content6_title')}</h2>
          <p className="description">{t('main2_content6_description')}</p>
          <div className="main_box6_scroll_box">
            <div className="scroll">
              <div className="scroll_boxes">
                <Main6Box title={t('main2_content6_inner1_title')} description={t('main2_content6_inner1_description')} />
                <Main6Box title={t('main2_content6_inner2_title')} description={t('main2_content6_inner2_description')} />
                <Main6Box title={t('main2_content6_inner3_title')} description={t('main2_content6_inner3_description')} />
                <Main6Box title={t('main2_content6_inner4_title')} description={t('main2_content6_inner4_description')} />
              </div>
              <div className={classNames(`scroll_box`, {ko : i18n.language === 'ko' || i18n.language === 'ko-KR' })}></div>
            </div>
          </div>
        </div>
      </div>

      {/* main box 7 */}
      <div className="main_box7">
        <h2 className="title">{t('main2_content7_title')}</h2>
        <div className="content3_inner">
          <TeamBox description={t('')} name={t('main2_content7_inner1_name')} roll={t('main2_content7_inner1_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner2_name')} roll={t('main2_content7_inner2_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner3_name')} roll={t('main2_content7_inner3_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner4_name')} roll={t('main2_content7_inner4_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner5_name')} roll={t('main2_content7_inner5_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner6_name')} roll={t('main2_content7_inner6_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner7_name')} roll={t('main2_content7_inner7_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner8_name')} roll={t('main2_content7_inner8_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner9_name')} roll={t('main2_content7_inner9_roll')} />
          <TeamBox description={t('')} name={t('main2_content7_inner10_name')} roll={t('main2_content7_inner10_roll')} />
        </div>
      </div>

    </div>
  );
}

export default Main2;