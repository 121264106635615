const ROUTE_SETTING = 'wallet/ROUTE_SETTING' as const;

type RouteAction =
  | ReturnType<typeof connectRouteSetting>
;

export const connectRouteSetting = (diff: string) => ({
  type: ROUTE_SETTING,
  payload: diff
});

export type RouteState = {
  status: string;
};

const initialState: RouteState = {
  status: 'Home',
};

function routes(
  state: RouteState = initialState,
  action: RouteAction
): RouteState {
  switch (action.type) {
    case ROUTE_SETTING:
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

export default routes;