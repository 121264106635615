import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

interface MetaProps {
  metaTagNameTitle?: string;
  metaDescription?: string;
  url?: string;
}

const MetaTag = ({ metaTagNameTitle, metaDescription, url }: MetaProps) => {
  
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{metaTagNameTitle ? metaTagNameTitle : t(`meta_tag_name_title`)}</title>
      <meta name="title" content={ metaTagNameTitle ? metaTagNameTitle : t(`meta_tag_name_title`)}/>
      <meta name="keywords" content={t(`meta_keywords`)}/>
      <meta name="description" content={metaDescription ? metaDescription : t(`meta_tag_description`)}/>
      <meta property="og:url" content={url ? url : "https://exais.io/"}/>
      <meta property='og:type' content='website' />
      <meta property="og:title" content={metaTagNameTitle ? metaTagNameTitle : t(`meta_tag_name_title`)}/>
      <meta property="og:image" content="https://exais.io/meta_images.png"/>
      <meta property="og:image:secure_url" content="https://exais.io/meta_images.png"/>
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta property="og:description" content={metaDescription ? metaDescription : t(`meta_tag_description`)}/>
      <meta property="og:site_name" content="exais" /> 
      <meta property="twitter:card" content={t(`meta_tag_twitter_card`)}/>
      <meta property="twitter:url" content={t(`meta_tag_twitter_url`)}/>
      <meta property="twitter:title" content={ metaTagNameTitle ? metaTagNameTitle : t(`meta_tag_name_title`)}/>
      <meta property="twitter:description" content={metaDescription ? metaDescription : t(`meta_tag_description`)}/>
      <meta property="twitter:image" content="https://exais.io/meta_images.png"/>
      <meta property="twitter:site" content={t(`meta_tag_twitter_site`)}/>
      <meta property="twitter:creator" content={t(`meta_tag_twitter_creator`)}/>
    </Helmet>  
  );
}
export default MetaTag;