import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';

import wallet from './wallet';
import routes from './routes';
import modal from './modal';
import login from './login';

const persistConfig = {
  key: "root",
  // localStorage에 저장합니다.
  storage: storageSession,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ["wallet", "modal", "login"]
  // blacklist -> blacklist에 있는 것만 제외합니다
};

const rootReducer = combineReducers({
  wallet,
  routes,
  modal,
  login
});

export default persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export * from './wallet';
export * from './routes';
export * from './modal';
export * from './login';