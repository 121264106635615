import { useState, useEffect } from 'react';

import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AOS from "aos";
import "aos/dist/aos.css";

import { RootState, initWallet } from './store';
import { history } from './utils';

import { Footer, Header, MetaTag } from './components';
import { Main, Main2, Exais, ExaFoundation, Ecosystem, Ecosystem2, Community, Explorer, PrivacyPolicy, NotFound } from './pages';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {

  const dispatch = useDispatch();
  
  // Now url
  const location = useLocation();
  const {t, i18n} = useTranslation();
  // Header Menu
  const [title, setTitle] = useState([
    // { id: 1, title: "Exais 1.0", link: "/exais" },
    // { id: 2, title: "Exa Foundation", link: "/exafoundation" },
    { id: 3, title: "Ecosystem", link: "/ecosystem" },
    { id: 4, title: "Community", link: "/community" },
    { id: 5, title: "Explorer", link: "/explorer" },
    { id: 6, title: "WhitePaper", link: "/whitepaper" },
  ]);

  // 404 Not Found page 처리를 위해 Outlet 처리
  const RouteLayout = () => {
    return(
      <>
      {/* header */}
      <Header
        title={title}
        location={location.pathname}
        status={status} 
      />
      {/* //header */}
      <Outlet />
      {/* footer */}
      <Footer />
      {/* //footer */}
      </>
    );
  }

  // Now page
  //   const [status, setStatus] = useState("Home");
  const status = useSelector((state: RootState) => state.routes.status);

  const check = useSelector((state:any) => state.login.token);

  useEffect(() => {
    AOS.init();
  });

  useEffect(() => {
    const unlistenHistoryEvent = history.listen(({action})=> {
      if(action === "POP") {
        if(check) {
          console.log("로그인 한 사용자");
          dispatch(initWallet(true));
        } else {
          dispatch(initWallet(false));
        }
      }
    });
    return unlistenHistoryEvent;
  }, [check]);

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  return (
    <div className="App">
      <MetaTag />
      {/* container */}
      <Routes>
        <Route element={<RouteLayout />}>
          {/* 임시로 main/ecosystem 페이지 2로 추가 후 작업 22.10.31 */}
          {/* <Route path="/" element={<Main />} /> */}
          <Route path="/" element={<Main2 />} />
          <Route path="/exais" element={<Exais />} />
          <Route path="/exafoundation" element={<ExaFoundation />} />
          {/* <Route path="/ecosystem" element={<Ecosystem />} /> */}
          <Route path="/ecosystem" element={<Ecosystem2 />} />
          <Route path="/community" element={<Community />} />
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {/* //container */}
      
    </div>
  );
}

export default App;
