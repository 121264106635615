import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { legacy_createStore as createStore } from 'redux';

import rootReducer from './store';

import App from './App';

// import './assets/css/font.css';
import './assets/css/reset.css';
import { HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from 'react-dom';

const store = createStore(rootReducer);
const persistor = persistStore(store);


const element = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// const meta = (
//   <MetaTag />
// );

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const root = ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, element);
root.render(element);


// const rootElement = document.getElementById("root") as HTMLElement;
// if (rootElement.hasChildNodes()) {
//   hydrate(element, rootElement);
// } else {
//   render(element, rootElement);
// }