type RoadMapProps = {
  date: string;
  description: string;
}

const RoadMapBox = ({ date, description }: RoadMapProps) => {
  return (

    <div
      className="content5_dot"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="content5_dot_box">
        <p className="date">{date}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
}

export default RoadMapBox;