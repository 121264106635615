type ChannelProps = {
  channel: string;
  hash: string;
}

const ChannelBox = ({channel, hash}: ChannelProps) => {
    return(
        <div 
            className="content3_box" 
            onClick={() => {
                if(channel === 'Medium') {
                    window.open('https://www.medium.com/EXAIS');
                } else if (channel === 'Facebook') {
                    window.open('https://www.facebook.com/people/Exais-IO/100085976002306/');
                } else if (channel === 'Twitter') {
                    window.open('https://twitter.com/EXAIS_1018');
                } else if (channel === 'Discord') {
                    window.open('https://discord.com/invite/ZcYHnUF4ad');
                } else {
                    return false;
                }
            }}
            data-aos="fade-up"
            data-aos-duration="1000"
        > 
            <div className="image"></div>
            <div className="content3_box_inner">
                <p className="channel">{channel}</p>
                <p className="hash">{hash}</p>
            </div>
        </div>
    );
}

export default ChannelBox;