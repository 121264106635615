import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState, connectWalletSetting, connectModalSetting, connectRouteSetting } from '../../store';

import { HeaderProps } from './Header.model';

import { Wallet } from '../../pages/index';
import { AlertModal } from '../index';

import './header.scss';

const Header = ({ title, location }: HeaderProps) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // 지갑 open 여부
  const walletOpen = useSelector((state: RootState) => state.wallet.open);

  // modal open 여부
  const modalOpen = useSelector((state: RootState) => state.modal.open);

  const [gnbOpen, setGnbOpen] = useState(false);

  const navMap = title.map((data: any) => (
    <li
      className={location === data.link ? "on" : ""}
      key={data.id}
    >
      <Link
        onClick={(e) => {
          if (data.title === "Community") {
            e.preventDefault();
            dispatch(connectModalSetting(!modalOpen));
          } else if (data.title === "WhitePaper") {
            e.preventDefault();
            window.open(`https://drive.google.com/file/d/1M2ZT8qu9pw3Q-vUL4H83Qftp_bcZC-P2/view?usp=sharing`, `_blank`);
          } else if (data.title === "Explorer") {
            e.preventDefault();
            window.open(`https://explorer.exais.net/txs`, `_blank`);
          } else {
            window.scrollTo(0, 0);
            dispatch(connectRouteSetting(data.title));
            setGnbOpen(false);
          }
        }}
        to={data.link}
      >
        {
          data.title === "Exais 1.0" 
          ? t("exais_1.0") 
          : data.title === "Exa Foundation" 
          ? t("exa_foundation") 
          : data.title === "Ecosystem" 
          ? t("ecosystem") 
          : data.title === "Community" 
          ? t("community") 
          : data.title === "Explorer" 
          ? t("explorer")
          : t("whitepaper")
        }
      </Link>
    </li>
  ));

  useEffect (() => {
    const body = document.getElementsByTagName('body')[0];
    if (gnbOpen || modalOpen || walletOpen) {
      body.classList.add('scrollLock');
    } else {
      body.classList.remove('scrollLock');
    }
  }, [gnbOpen, modalOpen, walletOpen]);

  return (
    <header className={gnbOpen ? "header on" : "header"}>

      {/* logo */}
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          // setStatus("Home");
        }}
        to="/"
      >
        <div className="logo_box" ></div>
      </Link>

      {/* gnb */}
      <div className="gnb">
        {gnbOpen ? (
          <div className='blur_box'></div>
        ) : null}
        <div className={gnbOpen ? "gnb_mobile_button on" : "gnb_mobile_button"}
          onClick={() => {
            setGnbOpen(!gnbOpen);
          }}
        >
          <div className="gnb_mobile_button_bar"></div>
        </div>

        <ul className={gnbOpen ? "gnb_box on" : "gnb_box"}>
          {navMap}
        </ul>
        {/* <div
          className="wallet"
          onClick={(e) => {
            // e.preventDefault();
            // onModalSetting(!modalOpen);
            dispatch(connectWalletSetting(!walletOpen));
          }}
        ></div>
        {walletOpen ? <Wallet /> : null} */}
      </div>
      {modalOpen && <AlertModal description={t("modal_alert_waiting")} confirm={t("modal_alert_waiting_confirm")} />}
    </header>
  );
}

export default Header;