import { Dispatch, SetStateAction } from "react";

import classNames from 'classnames';

import './normalButton.scss';

type NormalButtonProps = {
  button: string;
  arrow?: boolean;
  disabled?: boolean;
  onClick?: Function;
}

const NormalButton = ({ button, arrow, disabled, onClick }: NormalButtonProps) => {
  if (arrow === null || arrow === undefined || arrow === false) {
    return (
      <div
        className={classNames(`normal_button`, { 'disabled': disabled })}
        data-aos="fade-up"
        data-aos-duration="1000"
        onClick={() => {
          if (onClick === undefined) {
            return false;
          } else {
            onClick();
          }
        }}
      >
        <p>{button}</p>
      </div>
    );
  } else {
    return (
      <div
        className={classNames(`normal_button`, { 'disabled': disabled })}
        data-aos="fade-up"
        data-aos-duration="1000"
        onClick={() => {
          if (onClick === undefined) {
            return false;
          } else {
            onClick();
          }
        }}
      >
        <p>{button}</p>
        <div className="arrow"></div>
      </div>
    );
  }
}

export default NormalButton;