import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Languages, languages } from '../../assets/locales/i18n';

import { connectRouteSetting } from '../../store';

import './footer.scss';

const Footer = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [languageOpen, setLanguageOpen] = useState(false);

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  const handlerLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
    sessionStorage.setItem('language',lang);
  }

  return (
    <footer className="footer">
      <div className="footer_inner">

        <div className="footer_box1">
          <div className="footer_box1_inner">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(connectRouteSetting("Privacy Policy"));
              }}
              to={"/privacypolicy"}
            >
              {t('privacy_policy')}
            </Link>
            <a href='mailto:soo@nextor.ai;'>{t('contact')}</a>
          </div>
          <p className="copyright">EXAIS is Foundation 2022. All rights reserved.</p>
        </div>

        <div className="footer_box2">
          {/* SNS */}
          <div className="sns_box">
            <div className="medium" onClick={() => {
              window.open('https://exais.medium.com/');
            }}></div>
            <div className="facebook" onClick={() => {
              window.open('https://www.facebook.com/people/Exais-IO/100085976002306/');
            }}></div>
            <div className="twitter" onClick={() => {
              window.open('https://twitter.com/EXAIS_1018');
            }}></div>
            <div className="discord" onClick={() => {
              window.open('https://discord.com/invite/ZcYHnUF4ad');
            }}></div>
          </div>

          {/* language */}
          <div
            className={languageOpen ? "language_box on" : "language_box"}
            onClick={() => {
              setLanguageOpen(!languageOpen);
            }}
          >
            <ul className="language_box_inner">
              {/* {LanguageMap} */}
              {languages.map((lang: any) => (
                <li
                  key={lang}
                  className={lang.substr(0, 2) === i18n.language ? 'on' : ""}
                  onClick={() => handlerLanguage(lang)}
                >
                  {
                    lang.substr(0, 2) === 'ko'
                      ? "Korean" 
                      : lang.substr(0, 2) === 'en' 
                      ? "English" 
                      : "Language"
                  }
                </li>
              )
              )}
            </ul>
            <p>
              {
                i18n.language === 'ko' || i18n.language === 'ko-KR' 
                  ? "Korean" 
                  : i18n.language === 'en' || i18n.language === 'en-US' 
                  ? "English" 
                  : "Language"
              }
            </p>
            <div className={languageOpen ? "language_arrow on" : "language_arrow"}></div>
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;