import { useTranslation } from 'react-i18next';
import { MetaTag } from '../../components';

import "./privacyPolicy.scss";

const PrivacyPolicy = () => {

    const { t } = useTranslation();

    return (
        <div className="pp_container">
            <MetaTag metaTagNameTitle={t(`meta_tag_pp_title`)} metaDescription={t(`meta_tag_pp_description`)} url="https://exais.io/privacypolicy" />
            <div className="content1"></div>
            <div className="content2">
                <p 
                    className="title"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >{t('pp_page_content2_title')}</p>
                <p 
                    className="description"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    {t('pp_page_content2_description')}
                    <span>{t('pp_page_content2_subtitle1')}</span>
                    {t('pp_page_content2_subdescription1')}
                    <span>{t('pp_page_content2_subtitle2')}</span>
                    {t('pp_page_content2_subdescription2')}
                    <span>{t('pp_page_content2_subtitle3')}</span>
                    {t('pp_page_content2_subdescription3')}
                    <span>{t('pp_page_content2_subtitle4')}</span>
                    {t('pp_page_content2_subdescription4')}
                    <span>{t('pp_page_content2_subtitle5')}</span>
                    {t('pp_page_content2_subdescription5')}
                    <span>{t('pp_page_content2_subtitle6')}</span>
                    {t('pp_page_content2_subdescription6')}
                    <span>{t('pp_page_content2_subtitle7')}</span>
                    {t('pp_page_content2_subdescription7')}
                    <span>{t('pp_page_content2_subtitle8')}</span>
                    {t('pp_page_content2_subdescription8')}
                    <span>{t('pp_page_content2_subtitle9')}</span>
                    {t('pp_page_content2_subdescription9')}
                    <span>{t('pp_page_content2_subtitle10')}</span>
                    {t('pp_page_content2_subdescription10')}
                    <span>{t('pp_page_content2_subtitle11')}</span>
                    {t('pp_page_content2_subdescription11')}
                    <a className="send_mail" href="mailto:Soo@exais.io">Soo@exais.io</a>
                    {t('pp_page_content2_subdescription11_1')}
                    <a className="send_mail" href="mailto:Help@exais.io">Help@exais.io</a>
                    <span>{t('pp_page_content2_subtitle12')}</span>
                    {t('pp_page_content2_subdescription12')}
                    {t('pp_page_content2_subdescription12_1_1')}
                    (<a className="send_mail" href={"https://"+t('pp_page_content2_subdescription12_1_2')} target='_blank'>{t('pp_page_content2_subdescription12_1_2')}</a>)
                    {t('pp_page_content2_subdescription12_2_1')}
                    (<a className="send_mail" href={"https://"+t('pp_page_content2_subdescription12_2_2')} target='_blank'>{t('pp_page_content2_subdescription12_2_2')}</a>)
                    {t('pp_page_content2_subdescription12_3_1')}
                    (<a className="send_mail" href={"https://"+t('pp_page_content2_subdescription12_3_2')} target='_blank'>{t('pp_page_content2_subdescription12_3_2')}</a>)
                    {t('pp_page_content2_subdescription12_4_1')}
                    (<a className="send_mail" href={"https://"+t('pp_page_content2_subdescription12_4_2')} target='_blank'>{t('pp_page_content2_subdescription12_4_2')}</a>)
                    <span>{t('pp_page_content2_subtitle13')}</span>
                    {t('pp_page_content2_subdescription13')}
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;