import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { AboutBox, MetaTag } from "../../components";

import "./ecosystem.scss";

const Ecosystem2 = () => {

  const { t, i18n } = useTranslation();

  const openWeb = () => {
    window.open('https://zemi.world/', '_blank');
  }

  return (
    <div className={classNames(`ecosystem_container`, {ko : i18n.language === "ko-KR"})}>
      <MetaTag metaTagNameTitle={t(`meta_tag_ecosystem_title`)} metaDescription={t(`meta_tag_ecosystem_description`)} url="https://exais.io/ecosystem" />
      <div className="content1">
        <AboutBox title={t('ecosystem2_content1_title')} subtitle={t('ecosystem2_content1_subtitle')} description={t('ecosystem2_content1_description')} button={t('ecosystem2_content1_button')} arrow={true} onClick={openWeb} />
      </div>
      <div className="content2">
        <AboutBox title={t('ecosystem2_content2_title')} description={t('ecosystem2_content2_description')} button={t('ecosystem2_content1_button')} arrow={true} onClick={openWeb} />
        <AboutBox title={t('ecosystem2_content3_title')} description={t('ecosystem2_content3_description')} button={t('ecosystem2_content1_button')} arrow={true} onClick={openWeb} />
        <AboutBox title={t('ecosystem2_content4_title')} description={t('ecosystem2_content4_description')} button={t('ecosystem2_content1_button')} arrow={true} onClick={openWeb} />
      </div>
    </div>
  );
}

export default Ecosystem2;